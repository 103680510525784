import { useState, useEffect } from 'react';

export default () => {
    //滾動座標State
    const [scrollY, setScrollY] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    //監聽滾動事件並更新滾動座標State
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollY(window.scrollY);
        });
        window.addEventListener('resize', () => {
            setWindowHeight(window.innerHeight);
        });
    }, []);

    //根據滾動座標State計算背景移動距離
    const move = scrollY / windowHeight * -10;

    //渲染背景
    return (
        <div
            style={{
                zIndex: '1',
                position: 'fixed',
                inset: '0px',
                // transform: `translateY(${move}%)`,
                backgroundImage: `url("assets/main_BG.jpg")`,
                backgroundSize: `cover`,
                backgroundRepeat: `repeat`,
            }}
        >
            <video
                // id="loopV"
                playsInline
                autoPlay
                muted
                loop
                style={{
                    width: '100vMax',
                    height: 'auto',
                    maxWidth: 'unset',
                    // position: 'fixed',
                    // right: '0',
                    // bottom: '0',
                    // minWidth: '100%',
                    // minHeight: '100%'
                }}
                >
                <source 
                    src="assets/main_BG_H265_3.mp4" 
                    type="video/mp4" 
                    loop
                />
            </video>
            <video
                autoPlay
                muted
                loop
                style={{
                    width: '100vMax',
                    height: 'auto',
                    maxWidth: 'unset',
                    transform: 'scaleX(-1) rotate(180deg)',
                }}
            >
                <source 
                    src="assets/main_BG_H265_3.mp4" 
                    type="video/mp4" 
                    loop
                />
            </video>
            {/* <video
                autoPlay
                muted
                loop
                style={{
                    width: '100vMax',
                    height: 'auto',
                    transform: `translateY(${move}%)`,
                }}
            >
                <source 
                    src="assets/main_BG_H265.mp4" 
                    type="video/mp4" 
                    loop
                />
            </video> */}
        </div>
    );
};