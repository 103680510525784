import { Box, Img, Flex, Link } from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';

export default ({imageUrl, scrollY, windowHeight}) => {
    const [scale, setScale] = useState(1);
    const ref = useRef();

    useEffect(() => {
        if(ref.current){
            const range = 100;
            const top = ref.current.getBoundingClientRect().top;
            const mid = top - windowHeight / 2;
            let abs = Math.abs(mid);
            if(abs > range) abs = range;
            const val = (1 - abs / range);
            setScale(val*2 + 1);
        }
    }, [scrollY, windowHeight]);

    return (
        <Img
            ref={ref} 
            w='50px' 
            h='50px' 
            // bgImg={imageUrl} 
            src={imageUrl} 
            bgSize='cover' 
            rounded='50%' 
            border='1px solid white'
            transform={`scale(${scale})`}
            zIndex={Math.round(scale) * 10}
            transition={`transform 0.1s`}
        />
    );
}