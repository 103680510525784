import { Box, Img, Flex, Link } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import ProfilePhoto from './ProfilePhoto';

export default () => {

    //滾動座標State
    const [scrollY, setScrollY] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    //監聽滾動事件並更新滾動座標State
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollY(window.scrollY);
        });
        window.addEventListener('resize', () => {
            setWindowHeight(window.innerHeight);
        });
    }, []);

    
    return (
        <div
            style={{
                position: 'relative',
                zIndex: '2',
                fontSize:'16px',
                color: '#b3b3b4',
                fontWeight: '500' 
            }}
        >
            <Flex
                w='100%'
                h='800px'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                gap="20px"
                fontSize={{base:'lg', lg:'xl'}}
            >
                <Img maxW='160px' src='.\assets\wellplanet.webp'></Img>
                <Box letterSpacing='2.5px' textAlign='center' mt='10' mb='-2'>Have Fun while You Are Changing the world.</Box>
                <Box letterSpacing='5.5px'mt='0' pt='0' >『 玩樂中體驗你的利他宇宙 』</Box>
            </Flex>

            
            <Flex
                id='yoyo'
                zIndex='1000'
                margin='auto'
                w='90%'
                maxW='800px'
                h='800px'
                justifyContent='center'
                alignItems='center'
                // gap='10px'
                flexDirection={{base:'column', lg:'column'}}
            >
                <Box fontSize={{base:'md', lg:'xl'}} m='3' w='90%' maxW='600px' textAlign='center' lineHeight='2'>
                    好好星球文化基金會成立於 2023 年<br/>
                    希望無論女孩或男孩<br/>
                    都能在玩樂中體驗你的利他宇宙<br/>
                    致力打造一個擁有利他文化的星球

                </Box>
                <Img maxW='260px' m='8' src='.\assets\logo_2.webp'></Img>
                {/* <Link href='https://www.google.com' borderRadius='100px' overflow='hidden'>
                    <Img src='https://fakeimg.pl/440x230/EFEFEF/12e0d0/?retina=1&text=Supports%20emojis!%20%F0%9F%98%8B'></Img>
                </Link> */}
            </Flex>
            

            
            <Flex
                margin='auto'
                w='90%'
                maxW='800px'
                h='800px'
                justifyContent='center'
                alignItems='center'
                // gap='10px'
                flexDirection={{base:'column', lg:'column'}}
            >
                <Box fontSize={{base:'md', lg:'xl'}} m='3' w='90%' maxW='600px' textAlign='center' lineHeight='2'>
                基金會旗下兩個品牌<br/>
                靈魂實驗所與酷童實驗所分別提供<br/>
                女孩、親子專屬的療癒、有趣體驗<br/>
                一起成為好星人！

                </Box>
                <Flex
                    w='100%'
                    h='800px'
                    justifyContent='center'
                    alignItems='center'
                    gap='30px'
                    flexDirection={{base:'column', lg:'row'}}
                >
                    <Link href='http://soullab.wellplanet.org.tw'>
                        <Img maxW='180px' src='.\assets\soullab_simple.webp'></Img>
                    </Link>
                    <Box mt='6'fontWeight='300' fontSize='xl' display={{base:'none', lg:'unset'}}>☚ 點擊前往 ☛</Box>
                    <Box mt='6'fontWeight='300' fontSize='xl' display={{base:'unset', lg:'none'}}>點擊圖片前往</Box>
                    <Link href='https://childgoodorgtw.wordpress.com'>
                        <Img maxW='180px' src='.\assets\childgoodlab.webp'></Img>
                    </Link>
                </Flex>
            </Flex>

            
            <Flex
                w='100%'
                h='800px'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                p='20px'
            >
                <Box fontSize='3xl' m='3' mb='20'>董事名單</Box>
                <Flex w='90%' maxW='600px' flexDirection='column' fontSize='lg'  gap='30px'>
                    {[
                        {
                            id: '好好星球生母',
                            title: '董事長',
                            name: '許書慈',
                            image: '許書慈.png'
                        },
                        {
                            id: '好男人代表',
                            title: '董事',
                            name: '劉蔚廷',
                            image: '劉蔚廷.png'
                        },
                        {
                            id: '好混亂製造者',
                            title: '董事',
                            name: '劉建成',
                            image: '劉建成.png'
                        },
                        {
                            id: '好好星球最不懂事',
                            title: '董事',
                            name: '陳昱築',
                            image: '陳昱築.png'
                        },
                        {
                            id: '好吃的蝦是橘色的',
                            title: '董事',
                            name: '陳秉良',
                            image: '陳秉良.png'
                        },
                        {
                            id: '好想打電話給你',
                            title: '董事',
                            name: '陳子倢',
                            image: '陳子倢.png'
                        },
                        {
                            id: '好一顆正念丸子',
                            title: '董事',
                            name: '施惠文',
                            image: '施惠文.png'
                        },
                    ].map((item) => (
                        <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                            <Flex fontSize={{base:'lg', lg:'xxl'}} flex={{base:'2', lg:'3'}} flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                                <Box fontWeight='bold' >{item.id}</Box>
                                <Box mr='10'>{item.title}</Box>
                            </Flex>
                            <ProfilePhoto imageUrl={`./assets/boards/${item.image}`} scrollY={scrollY} windowHeight={windowHeight}/>
                            <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>{item.name}</Box>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
            
            <Flex
                w='100%'
                h='800px'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                p='20px'
                mt='20'
            >
                <Box fontSize='3xl' m='3' mb='20'>團隊成員</Box>
                <Flex w='90%' maxW='600px' flexDirection='column' fontSize='lg' gap='30px'>
                    {[
                        {
                            id: '好好星球艦長',
                            title: '執行長',
                            name: '張希慈',
                            image: '張希慈.jpg'
                        },
                        {
                            id: '好好萬能叮噹',
                            title: '行政經理',
                            name: '陳泳璇',
                            image: '陳泳璇.jpg'
                        },
                        {
                            id: '好好好吉祥物',
                            title: '專案經理',
                            name: '周宇心',
                            image: '周宇心.png'
                        },
                        {
                            id: '棉花糖泡泡槍',
                            title: '專案經理',
                            name: '姚品瑄',
                            image: '姚品瑄.png'
                        },
                        {
                            id: '好好魔法戰士',
                            title: '專案助理',
                            name: '黃品禎',
                            image: '黃品禎.png'
                        },
                        {
                            id: '好好靈魂袋熊',
                            title: '專案助理',
                            name: '胡喻翔',
                            image: '胡喻翔.png'
                        },
                        {
                            id: '好大一聲轟隆',
                            title: '實習生',
                            name: '洪瑞隆',
                            image: '洪瑞隆.png'
                        },
                        {
                            id: '好星煉金術師',
                            title: '實習生',
                            name: '陳奕妏',
                            image: '陳奕妏.jpg'
                        },
                        {
                            id: '好好星球艦貨',
                            title: '美感設計',
                            name: '蘇子懿',
                            image: 'Ziyi Su（美感設計）.png'
                        },
                        
                    ].map((item) => (
                        <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                            <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                                <Box fontWeight='bold'>{item.id}</Box>
                                <Box mr='10'>{item.title}</Box>
                            </Flex>
                            <ProfilePhoto imageUrl={`./assets/member/${item.image}`} scrollY={scrollY} windowHeight={windowHeight}/>
                            <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>{item.name}</Box>
                        </Flex>
                    ))}
                    {/* <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好星球艦長</Box>
                            <Box>執行長</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/張希慈.jpg' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>張希慈</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好萬能叮噹</Box>
                            <Box>行政經理</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/陳泳璇.jpg' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>陳泳璇</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好好吉祥物</Box>
                            <Box>專案經理</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/周宇心.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>周宇心</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>棉花糖泡泡槍</Box>
                            <Box>專案經理</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/姚品瑄.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>姚品瑄</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好魔法戰士</Box>
                            <Box>專案助理</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/黃品禎.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>黃品禎</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好靈魂袋熊</Box>
                            <Box>專案助理</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/胡喻翔.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>胡喻翔</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好大一聲轟隆</Box>
                            <Box>實習生</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/洪瑞隆.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>洪瑞隆</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好星煉金術師</Box>
                            <Box>實習生</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/陳奕妏.jpg' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>陳奕妏</Box>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' gap='20px' alignItems='center'>
                        <Flex flex={{base:'2', lg:'3'}}  flexDirection={{base:'column', lg:'row'}} justifyContent='space-between'>
                            <Box fontWeight='bold'>好好星球艦貨</Box>
                            <Box>美感設計</Box>
                        </Flex>
                        <Img w='50px' h='50px' bgImg='./assets/member/Ziyi Su（美感設計）.png' bgSize='cover' rounded='50%' border='1px solid white'/>
                        <Box flex='1' textAlign='right' minW='70px' fontSize='xl' fontWeight='bold'>蘇子懿</Box>
                    </Flex> */}
                </Flex>
            </Flex>
                                 
            <Flex
                w='100%'
                h='800px'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                gap='20px'
                fontSize={{base:'lg', lg:'xl'}}
            >
                <Box >新北文發字 1120503099 號</Box>
                <Box >財團法人新北市好好星球文化基金會</Box>
                <Box >統一編號 92628887</Box>
            </Flex>    

            <Flex
                w='100%'
                h='800px'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                gap='20px'
            >
            
                <Box >聯繫我們</Box>
                {/* <Flex
                    w='100%'
                    justifyContent='center'
                    alignItems='center'
                    gap="50px"
                > */}
                <Link href='mailto:service@wellplanet.org.tw'>
                    <Box >service@wellplanet.org.tw</Box>
                    {/* <Img src='.\assets\icon_ig.webp'/> */}
                </Link>
                <Box >0972872478</Box>
                {/* <Link href='https://www.google.com'>
                    <Img src='.\assets\icon_line.webp'/>
                </Link> */}
                
                {/* </Flex> */}
            </Flex>
                     
            <Flex
                w='100%'
                p='4'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                gap='20px'
                bgColor='#666'
                zIndex='1000'
            >
                <Box >Copyright® 為財團法人新北市好好星球文化基金會所有</Box>
            </Flex>
            








     </div>
    );
};