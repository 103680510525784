//查克拉
import { ChakraProvider } from '@chakra-ui/react';
import theme from './components/theme/theme';

//元件
import BackgroundView from './components/BackgroundView';
import MainView from './components/MainView';
import MouseView from './components/MouseView';


export default function App(){
    

    return (
        <ChakraProvider theme = {theme}>
            <BackgroundView />
            <MainView />
            <MouseView />
        </ChakraProvider>
    );
}