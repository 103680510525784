import { useState , useEffect } from 'react';

export default () => {
    //滑鼠座標State
    const [mousePosition, setMousePosition] = useState({x: '50%',y: '50%', active: false});

    //監聽滑鼠事件並更新滑鼠座標State
    useEffect(() => {
        window.addEventListener('mousemove',(e) => {
            setMousePosition({x: e.x, y: e.y, active: true});
        })
        document.addEventListener("mouseleave", () => {
            setMousePosition((obj) => {
                return {...obj, active: false}
            })
        });
    }, []);

    //根據滑鼠座標State渲染Dom
    return (
        <div id='mouseview'
            style={{
                zIndex: '-500',
                pointerEvents: 'none',
                // opacity: mousePosition.active ? '1' : '0',
                position: 'fixed',
                zIndex: '3',
                top: mousePosition.y,
                left: mousePosition.x,
                // top: '200px',
                // left: '200px',
                width: '800px',
                height: '800px',
                transform: 'translate(-4400px, -4400px)',
                // background: 'white',
                border: '4000px solid white',
                borderRadius: '50%',
                boxSizing: 'content-box',
                filter: 'blur(100px)',
                mixBlendMode: 'saturation',
                opacity: '0.4',
            }}
        >
        </div>
        
    );
}